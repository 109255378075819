<template lang="">
  <IAmOverlay :loading="isLoading">
    <BRow>
      <BCol cols="2">
        <div class="d-flex flex-column gap-3 p-2">
          <BButton
            pill
            variant="airline"
            class="w-100"
            :disabled="isLoading"
            @click="onExportPDFHandle"
          >
            {{ $t('invoice.btn.exportPDF') }}
          </BButton>
          <BButton
            pill
            variant="airline"
            class="w-100"
            :disabled="!BUTTON_ACTION_PERMISSIONS?.[invoiceData?.invoiceStatus]?.[invoiceData?.releaseStatus]?.EDIT
              || !checkPermissionForReleaseInvoice(invoiceData, INVOICE_REQUEST_STATUS_PERMISSIONS.EDIT)
              || isLoading || !companyId"
            @click="handleEditInvoice"
          >
            {{ $t('invoice.btn.edit') }}
          </BButton>
          <BButton
            pill
            class="w-100"
            variant="airline"
            :disabled="!BUTTON_ACTION_PERMISSIONS?.[invoiceData?.invoiceStatus]?.[invoiceData?.releaseStatus]?.SEND_EMAIL
              || !checkPermissionForReleaseInvoice(invoiceData, INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL)
              || isLoading || !companyId"
            @click="onSendEmailHandle"
          >
            {{ $t('invoice.btn.sendEmail') }}
          </BButton>
          <BButton
            v-if="checkPermissionForReleaseInvoice(invoiceData, INVOICE_REQUEST_STATUS_PERMISSIONS.RELEASE)"
            pill
            class="w-100"
            variant="airline"
            :disabled="!((BUTTON_ACTION_PERMISSIONS?.[invoiceData?.invoiceStatus]?.[invoiceData?.releaseStatus]?.SIGN || invoiceData?.signStatus === 'UNSIGNED') && invoiceData.invNumber) || isLoading || !companyId || isInvoiceRequestAndViewF2"
            @click="onSignInvoiceHandle"
          >
            {{ $t('invoice.btn.signAndReleaseInvoice') }}
          </BButton>
          <BButton
            v-else
            pill
            class="w-100"
            :disabled="invoiceData.requestReleaseStatus === REQUEST_RELEASE_STATUS.PENDING"
            variant="airline"
            @click="onRequestReleaseInvoiceRequestHandle"
          >
            {{ $t('invoice.btn.requestReleaseInvoiceRequest') }}
          </BButton>

          <BButton
            pill
            variant="outline-secondary"
            @click="handleExit"
          >
            {{ $t('invoice.btn.exit') }}
          </BButton>
        </div>
      </BCol>
      <BCol
        cols="10"
        style="overflow: auto; max-height: 100vh"
      >
        <div class="p-1 border-1 border-dark">
          <VuePdfEmbed
            v-if="pdfData"
            ref="pdfFileRef"
            :source="pdfData"
          />
        </div>
      </BCol>
    </BRow>

    <InvoiceSignModal
      :invoice-data="invoiceData"
      :companyId="invoiceData?.company?.id ?? null"
      @refetch="fetchInvoicePdf"
    />
  </IAmOverlay>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import upperFirst from 'lodash/upperFirst'

import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import useInvoiceHandle, { checkPermissionForReleaseInvoice, resolveRequestReleaseInvoiceStatus } from '@/views/invoices/useInvoiceHandle'
import invoiceStoreModule from '@/views/invoices/invoiceStoreModule'
import {
  CREATED_TYPE, INVOICE_LIST_PATH_NAME, INVOICE_REQUEST_LIST_PATH_NAME, INVOICE_REQUEST_STATUS_PERMISSIONS, REQUEST_RELEASE_STATUS,
} from '@/constants/invoice'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    VuePdfEmbed,
    InvoiceSignModal: () => import('@/views/invoices/components/InvoiceSignModal.vue'),
  },
  setup(_, { root }) {
    const {
      INVOICE_STORE,
      getInvoicePdfById,
      loading,
      BUTTON_ACTION_PERMISSIONS,
      getInvoiceById,
      sendMailInvoice,
      requestReleaseInvoiceRequest,
    } = useInvoiceHandle()
    const { router } = useRouter()

    const { toastError } = useToast()
    const pdfFileRef = ref()
    const pdfData = ref()
    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    const invoiceData = ref()
    const loadingInitData = ref(false)
    const fetchInvoicePdf = async (id = router.currentRoute.params.id) => {
      loadingInitData.value = true
      const promiseArr = [getInvoicePdfById(id), getInvoiceById(id)]
      const res = await Promise.all(promiseArr)
      pdfData.value = URL.createObjectURL(res[0].data)
      res[1].requestStatus = resolveRequestReleaseInvoiceStatus(res[1])
      invoiceData.value = res[1]
      loadingInitData.value = false
    }

    if (router.currentRoute.params.id) {
      fetchInvoicePdf(router.currentRoute.params.id)
    }

    function handleEditInvoice() {
      router.push({ name: 'apps-invoiceDetail', params: { id: router.currentRoute.params.id }, hash: '#edit' })
    }

    function handleExit() {
      router.push({ name: invoiceData.value.createdType === CREATED_TYPE.INVOICE ? INVOICE_LIST_PATH_NAME : INVOICE_REQUEST_LIST_PATH_NAME })
    }

    async function onSendEmailHandle() {
      const invoiceId = invoiceData.value?.id
      if (!invoiceId) return
      await sendMailInvoice(invoiceId)
    }
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const generateInvoiceNumberOnCreation = computed(() => agencyData.value?.company?.generateInvoiceNumberOnCreation ?? false)
    async function onSignInvoiceHandle() {
      if (!agencyData.value.company) {
        toastError('invoice.msg.cannotFindCompany')
        return
      }
      if (generateInvoiceNumberOnCreation.value !== !!invoiceData.value.invNumber) {
        toastError('invoice.msg.differenceGenerateInvoiceNumberOnCreationError')
        return
      }
      root.$bvModal.show('modal-sign-invoice')
    }

    function onExportPDFHandle() {
      const buyerNameModify = upperFirst(removeAccents(invoiceData.value.buyerName).replace(/\s+/g, ''))
      const fileName = `${invoiceData.value.referenceCode}_${invoiceData.value.templateNumber}${invoiceData.value.symbol}_${invoiceData.value.invNumber}${buyerNameModify ? `_${buyerNameModify}` : ''}.pdf`
      pdfFileRef.value.download(fileName)
    }

    const isLoading = computed(() => loading.value || loadingInitData.value)
    const companyId = computed(() => {
      const cpnId = store.getters['userStore/getAgencyCompany']?.id
      if (invoiceData.value?.company?.id !== cpnId
        && invoiceData.value?.createdType === CREATED_TYPE.RELEASE) {
        return true
      }
      return cpnId
    })

    async function onRequestReleaseInvoiceRequestHandle() {
      await requestReleaseInvoiceRequest(invoiceData.value.id)
      fetchInvoicePdf()
    }

    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isInvoiceRequestAndViewF2 = computed(() => invoiceData.value?.createdType === CREATED_TYPE.REQUEST && isRoleF2.value)
    return {
      handleEditInvoice,
      pdfData,
      handleExit,
      BUTTON_ACTION_PERMISSIONS,
      invoiceData,
      isLoading,
      onSendEmailHandle,
      onExportPDFHandle,
      onSignInvoiceHandle,
      pdfFileRef,
      fetchInvoicePdf,
      companyId,
      isInvoiceRequestAndViewF2,
      INVOICE_REQUEST_STATUS_PERMISSIONS,
      checkPermissionForReleaseInvoice,
      REQUEST_RELEASE_STATUS,
      onRequestReleaseInvoiceRequestHandle,
    }
  },
}
</script>
<style lang="">

</style>
